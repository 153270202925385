import {Router} from 'groutcho';
import _ from 'lodash';

import {session, NotFoundPage} from '@tripp/shared';
import {HomePage} from '../Home';
import {
  AddPersonaPage,
  CreateAccountPage
} from '../Account';
import {FeedPage} from '../Feed';
import {
  VerifyEmailPage,
  AuthenticationPage
} from '../Authentication';
import {
  CompanyDashboardPage,
  CompanyGiftsPage
} from '../Company';
import {
  ProfilePage,
  ProfileGiftsPage
} from '../Profile';
import {
  BillingPage,
  CreateBillingPage
} from '../Billing';

function companyRedirect (params) {
  const isAdmin = _.get(session, 'account.isAdmin', false);
  if (isAdmin) {
    return false;
  }

  const redirect = 'Home';

  const companyId = _.get(session, 'account.company.id', null);
  if (companyId !== params.id) {
    return redirect;
  }

  // const companyAdminPath = 'account.company.sessionWorker.isAdmin';
  // const isCompanyAdmin = _.get(session, companyAdminPath, false);
  // return isCompanyAdmin ? false : redirect;
  return false;
}

const routes = {
  Home: {
    pattern: '/',
    page: HomePage,
    session: true
  },
  CreateAccount: {
    pattern: '/account/create',
    page: CreateAccountPage,
    session: true
  },
  AddPersona: {
    pattern: '/account/persona',
    page: AddPersonaPage
  },
  Billing: {
    pattern: '/billing',
    page: BillingPage
  },
  CreateBilling: {
    pattern: '/billing/create',
    page: CreateBillingPage
  },
  SignIn: {
    pattern: '/signin',
    page: AuthenticationPage,
    session: false
  },
  SignUp: {
    pattern: '/signup',
    page: AuthenticationPage,
    session: false
  },
  NotFound: {
    pattern: '/404',
    page: NotFoundPage
  },
  VerifyEmail: {
    pattern: '/signin/verify',
    page: VerifyEmailPage,
    session: true
  },
  CompanyDashboard: {
    pattern: '/company/:id',
    page: CompanyDashboardPage,
    session: true,
    redirect: companyRedirect
  },
  CompanyGifts: {
    pattern: '/company/:id/gifts',
    page: CompanyGiftsPage,
    session: true,
    redirect: companyRedirect
  },
  Feed: {
    pattern: '/quaid',
    page: FeedPage,
    session: true
  },
  Profile: {
    pattern: '/profile',
    page: ProfilePage,
    session: true
  },
  ProfileGifts: {
    pattern: '/profile/gifts',
    page: ProfileGiftsPage,
    session: true
  }
};

const router = new Router({
  routes,
  redirects: {
    NotFound: (match)=> {
      return match ? false : 'NotFound';
    },
    Session: ({route})=> {
      const has_session = (route.session !== undefined);
      const require_session = (has_session && route.session);
      const require_no_session = (has_session && !route.session);
      const signed_in = session.signedIn();
      if (require_session && !signed_in) {
        return 'SignIn';
      }
      if (require_no_session && signed_in) {
        return 'Home';
      }
      return false;
    },
    Role: ({route})=> {
      if (!session.signedIn()) {
        return false;
      }
      const {role} = route;
      const shouldRedirect = (role && !session.hasRole(role));
      return shouldRedirect ? 'Home' : false;
    },
    EmailNotVerified: ({route})=> {
      if (!session.signedIn()) {
        return false;
      }
      const {user} = session;
      const notVerified = (route.session && user && !user.emailVerified);
      return notVerified ? 'VerifyEmail' : false;
    },
    CheckAccount: ({route})=> {
      if (!session.signedIn()) {
        return false;
      }
      const {account} = session;

      if (!account) {
        return 'CreateAccount';
      }

      const {personas} = account;
      const hasPersona = (personas.length > 0);
      if (!hasPersona) {
        console.log('checkaccount', 'AddPersona');
        return 'AddPersona';
      }
      if (route.is('AddPersona') || route.is('CreateAccount')) {
        console.log('checkaccount', 'Home');
        return 'Home';
      }

      console.log('checkaccount', false);
      return false;
    }
  }
});

export default router;
